import { Root, rootLoader as loader, useLoaderData, useTransition } from '@ubo/losse-sjedel';
import type { LinksFunction, RootProps } from '@ubo/losse-sjedel';
import PageNotFound from '~/components/layout/PageNotFound';
import styles from '~/global.css';
import FlexContent from './graphql/FlexContent';
import Queries from './graphql/Queries';
import PostTypes from './graphql/PostTypes';
import Progress from 'nprogress';
import stylesProgress from 'nprogress/nprogress.css';
import { useEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async';

export const flexData = {
  flexContent: FlexContent,
  queries: Queries,
  postTypes: PostTypes
};

export const links: LinksFunction = () => {
  return [
  {
    rel: 'preload',
    as: 'style',
    href: styles
  },
  {
    href: styles,
    rel: 'stylesheet'
  },
  {
    rel: 'stylesheet',
    href: stylesProgress
  },
  {
    href: '/manifest.json',
    rel: 'manifest'
  },
  {
    rel: 'icon',
    href: '/favicon-32x32.png',
    sizes: '32x32',
    type: 'image/png'
  },
  {
    rel: 'icon',
    href: '/favicon-16x16.png',
    sizes: '16x16',
    type: 'image/png'
  },
  {
    rel: 'apple-touch-icon',
    href: '/apple-touch-icon.png',
    sizes: '180x180',
    type: 'image/png'
  }];

};

export { loader };

export function CatchBoundary() {
  return <PageNotFound />;
}

Progress.configure({ showSpinner: false, easing: 'ease', speed: 500 });

export default function App() {
  const data = useLoaderData<RootProps>();
  const transition = useTransition();

  useEffect(() => {
    if (transition.state === 'loading' || transition.state === 'submitting') {
      Progress.start();
    } else {
      Progress.done();
    }
  }, [transition.state]);

  return (
    <HelmetProvider>
      <Root {...data} />
    </HelmetProvider>);

}